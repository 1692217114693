import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface TestSlice {
  value: Array<String> | null;
  status: 'null' | 'loading' | 'active';
}

const initialState: TestSlice = {
  value: [],
  status: 'null',
};

export const TestSlice = createSlice({
  name: 'testSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    testClear: (state) => {
      state.value = null;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    testSet: (state, action: PayloadAction<string>) => {
        if (state.value == null) {
            state.value = [];
        }
        else {
            state.value = [...state.value, action.payload];
        }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});

export const {testClear, testSet } = TestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTestValue = (state: RootState) => state.test.value;


export default TestSlice.reducer;
